import React from "react"
import loadable from '@loadable/component'
import SEO from '../components/SEO'


const FAQ = loadable(() => import('../components/FAQ'));
const Logo = loadable(() => import("../components/Logo"));
const Menu = loadable(() => import('../components/Menu'));
const Content = loadable(() => import('../components/Content'));
const Section = loadable(() => import('../components/Section'));
const InnerSection = loadable(() => import('../components/InnerSection'));

export default function Home() {
  return (
    <main>
      <SEO/>
      <Logo/>
      <Menu/>
      <Content>
        <Section title='Suporte' id='how'>
          <InnerSection>
            <h3>Dúvidas mais comuns:</h3>
            <ul>
              <li>
                <a
                  href='#time-to-deliver'
                  className='support-link'
                >
                  Quanto tempo demora pra entregar minha coleira?
                </a>
              </li>
              <li>
                <a
                  href='#when-to-charge'
                  className='support-link'
                >
                  Quando e como carregar minha coleira?
                </a>
              </li>
              <li>
                <a
                  href='#not-on-point-location'
                  className='support-link'
                >
                  Porque a localização do meu pet não está precisa?
                </a>
              </li>
              <li>
                <a
                  href='#configuration'
                  className='support-link'
                >
                  Como configurar minha coleira pela primeira vez?
                </a>
              </li>
              <li>
                <a
                  href='#lost-my-dog'
                  className='support-link'
                >
                  Perdi meu cachorro e agora?
                </a>
              </li>
              <li>
                <a
                  href='#why-location'
                  className='support-link'
                >
                  Por que o aplicativo precisa da minha localização?
                </a>
              </li>
              <li>
                <a
                  href='#sync-time'
                  className='support-link'
                >
                  Quanto tempo demora para os dados sincronizarem
                </a>
              </li>
              <li>
                <a
                  href='#why-health'
                  className='support-link'
                >
                  Por que o aplicativo usa meus dados de saúde?
                </a>
              </li>
              <li>
                <a
                  href='#collar-weight'
                  className='support-link'
                >
                  Quanto pesa a coleira?
                </a>
              </li>
              <li>
                <a
                  href='#collar-dimensions'
                  className='support-link'
                >
                  Quais as dimensões / tamanho da coleira?
                </a>
              </li>
              <li>
                <a
                  href='#how-contact'
                  className='support-link'
                >
                  Como entrar em contato?
                </a>
              </li>
            </ul>
          </InnerSection>
          <hr/>
          <InnerSection>
            <FAQ
              id="time-to-deliver"
              question='Quanto tempo demora pra entregar minha coleira?'
              answer='Nossa logística de entrega é moderna e rápida, a maioria dos
              envios acontecem no mesmo dia, alguns chegando no mesmo dia ou
              no dia seguinte quando dentro da grande São Paulo.'
            />
            <FAQ
              id="when-to-charge"
              question='Quando e como carregar minha coleira?'
              answer='O aplicativo Urdog vai te avisar uma vez que a bateria esteja
              fraca e também quando estiver carregada, mas você também vai saber por
              conta de um sinal de luz que acende na coleira quando a bateria está
              fraca e acende novamente quando a bateria esta 100% carregada.'
            />
            <FAQ
              id="not-on-point-location"
              question='Porque a localização do meu pet não está precisa?'
              answer='A coleira usa GPS para uma posição bem precisa, porém em áreas
              com pouco sinal pode ser que tenha que usar outras tecnologias de posicionamento
              que não são tão precisas quanto o GPS, isso costuma ser mais comum em areas muito remotas
              ou lugares muito fechados.'
            />
            <FAQ
              id="configuration"
              question='Como configurar minha coleira pela primeira vez?'
              answer='Basta instalar o aplicativo da Urdog pela App Store ou Google
              Play e criar uma conta, o aplicativo vai buscar uma coleira na proximidade
              e vincular a conta a ela.'
            />
            <FAQ
              id="lost-my-dog"
              question='Perdi meu cachorro e agora?'
              answer='Não se desespere, por conta da posição GPS, desde que haja
              sinal de celular aonde o cachorra esteja e a coleira tenha bateria
              você vai conseguir uma localização, no aplicativo clique no card do
              mapa e no botão "perdi meu cachorro" a posição do seu cachorro vai
              começar a ser trasmitida o mais rápido possível em relação ao tempo real.'
            />
            <FAQ
              id="why-location"
              question='Por que o aplicativo precisa da minha localização?'
              answer='Utilizamos a localização do celular para compará-la à da coleira,
              algumas dessas comparações permitem deixar o produto bem melhor, incluindo
              economia de bateria e melhor tracking de passeios e caminhadas.'
            />
            <FAQ
              id="why-health"
              question='Por que o aplicativo usa meus dados de saúde?'
              answer='O cachorro é um animal super companheiro e a sua rotina como dono
              pode afetar diretamente o seu comportamento, coletando esses dados podemos
              compará-los com os dados do seu pet no decorrer do tempo para gerar
              insights intessantes para você.'
            />
            <FAQ
              id="sync-time"
              question='Quanto tempo demora para os dados sincronizarem'
              answer='O tempo para atualização dos dados pode depender do sinal da coleira
              e também se você está próximo ao cachorro ou não, algun dados podem ser
              "pulados" dependo da situação e serem sincronizados mais tarde'
            />
            <FAQ
              id="collar-weight"
              question='Quanto pesa a coleira?'
              answer='Aproximadamente 70 gramas.'
            />
            <FAQ
              id="collar-dimensions"
              question='Quais as dimensões / tamanho da coleira?'
              answer='7cm x 4.7cm x 2.5cm'
            />
          </InnerSection>
          <InnerSection>
            <div id='how-contact' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: 250}}>
              <h3 style={{lineHeight: 1}}>
                Múltiplos canais para entrar em contato conosco:
              </h3>
              <br />
              <br />
              <a href="https://wa.me/message/P5BAD72JDJDVM1" target="_blank" style={{width: 430}}>
                <button type="button" style={{backgroundColor: '#25D366', width: '100%'}}>
                  Whatsapp
                </button>
              </a>
              <br />
              <a href="mailto:contato@urdog.com.br" style={{width: 430}}>
                <button type="button" style={{backgroundColor: '#683a32', width: '100%'}}>
                  Email: <small>contato@urdog.com.br</small>
                </button>
              </a>
              <br />
              <a href="tel:11939085089" target="_blank" style={{width: 430}}>
                <button type="button" style={{width: '100%'}}>
                  Telefone: <small>(11) 93908-5089</small>
                </button>
              </a>
            </div>
          </InnerSection>
        </Section>
      </Content>
    </main>
  )
}